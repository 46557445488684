import React from 'react'
import { Stack, Main,} from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import { Box } from 'theme-ui'
import {Helmet} from 'react-helmet'

export default () => (
  <>
    <Seo title='Cookies policy' />
    <Divider />
    <Stack>
      <Main>
        <PageTitle
          header="Cookies"
          subheader='We collect informations to provide a better services to all our users. The informations Fttalks.com collects, and how that informations are used, depends on how you use our Services and how you manage your privacy controls. Please, learn more by reading our cookies documentation. '
        />
        
<h2>
  Privacy Policy of www.fttalks.com
</h2>
        

This Application collects some Personal Data from its Users.
Personal Data collected for the following purposes and using the following services:
<un>
  <li>
        Access to third-party accounts
            Facebook account access
            </li>
<li> Permissions: Access private data
        Analytics
            Google Analytics with anonymized IP
            </li>
 <li> Personal Data: Tracker; Usage Data
        Hosting and backend infrastructure
            Netlify
   </li>          
   <li>
     Personal Data: various types of Data as specified in the privacy policy of the service
        Managing contacts and sending messages
            Mailchimp
   </li>
   <li>Personal Data: various types of Data
   </li>
</un>
<Box>
<h2> Contact information</h2>

        <p>Owner and Data Controller</p>

        <p>FashionTech Talks</p>

        <p>Owner contact email: me@lorenzocoppini.com</p>

        <p>Latest update: January 18, 2021</p> 
</Box>
<Helmet>
<script id="CookieDeclaration" src="https://consent.cookiebot.com/60209430-48c4-45f4-aa24-a476c1b79922/cd.js" type="text/javascript" async></script>
</Helmet>


      </Main>
    </Stack>
  </>
)
